import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Grid,
} from '@mui/material';
import AuthService from '../../../Services/auth.service';
import { useNavigate, Link } from 'react-router-dom';
import SearchItems from '../SearchItems';
import { Map } from '../Map';
import toast from 'react-hot-toast';
import { images } from '../../../Assets/images';
import { icons } from '../../../Assets/icons';
import { getVendorListingReqInterface } from '../../../api/types/dashboard';
import useAppContext from '../../../hooks/useAppContext';
import './List.css';
/* Map Import  */
import '../Map.css'; // Importing the external CSS for styling
import Slider from '@mui/material/Slider';
import ListSearchItems from './ListSearchItems';
import ListingFilters from './ListingFilters/ListingFilters';

interface CategoryList {
  id: number;
  name: string;
  logo_link: string;
  sequence: number;
  is_premium_category: number;
}
interface MapProps {
  itemData: any; // Replace `any` with the actual type of `itemData` for better type safety
}
interface MapData {
  vendor_list: { data: any }[];
}
/* Map Import  */
const List = () => {
  const authService = AuthService();
  const navigate = useNavigate();
  const { getVenorListingFunc, getDashboardDataListFunc } = useAppContext();
  const [dashboardData, setDashboardData] = useState<any>(null);
  const [mapData, setMapData] = useState<MapData | null>(null);
  const [plotData, setPlotData] = useState([]);

  console.log('plotData for map -->>', plotData);

  const location = useLocation();

  const { filter_type, lattitude, longitude, title } = location.state;

  const [customerDashboardListing, setCustomerDashboardListing] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [appliedFilters, setAppliedFilters] = useState<Record<string, any>>({});
  const itemsPerPage = '10';

  const getCustomerDashboardListing = async (
    page: string,
    filters: Record<string, any>,
  ) => {
    setLoading(true);

    try {
      const result: any = await getDashboardDataListFunc(
        filter_type,
        page,
        itemsPerPage,
        filters,
      );

      if (result === null) {
        toast.error('Failed to fetch data.');
        return;
      }

      if (result?.status_code === 200) {
        setCustomerDashboardListing(result.data.records || []);
        setTotalPages(result.data.total_pages || 1);
        setTotalRecords(result.data.total_records || 0);
      } else {
        toast.error(result?.message);
      }
    } catch (error) {
      console.error('Error fetching vendors:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (lattitude && longitude) {
      getCustomerDashboardListing(currentPage.toString(), {
        lattitude,
        longitude,
      });
    } else {
      getCustomerDashboardListing(currentPage.toString(), appliedFilters);
    }
    // getCustomerDashboardListing(currentPage.toString(), appliedFilters);
  }, [currentPage, filter_type]);

  useEffect(() => {
    document.body.classList.add('noScroll');
    return () => {
      document.body.classList.remove('noScroll');
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // Handle filter application
  const handleApplyFilters = (filters: Record<string, any>) => {
    const manageFilters = { ...filters };
    if (lattitude && longitude) {
      if (!manageFilters['lattitude'] && !manageFilters['longitude']) {
        manageFilters['lattitude'] = lattitude;
        manageFilters['longitude'] = longitude;
      }
      setAppliedFilters(manageFilters);
      setCurrentPage(1); // Reset to first page on new search
      getCustomerDashboardListing('1', manageFilters);
    } else {
      setAppliedFilters(manageFilters);
      setCurrentPage(1); // Reset to first page on new search
      getCustomerDashboardListing('1', manageFilters);
    }
    // setAppliedFilters(filters);
    // setCurrentPage(1); // Reset to first page on new search
    // getCustomerDashboardListing('1', filters);
  };

  const searchReq = location.state?.searchReq;

  useEffect(() => {
    if (searchReq?.latitude && searchReq?.longitude) {
      setMapData(prevMapData => ({
        ...prevMapData,
        vendor_list: prevMapData?.vendor_list || [],
        latitude: searchReq.latitude,
        longitude: searchReq.longitude,
      }));
    }
  }, [searchReq]);

  useEffect(() => {
    if (searchReq) {
      fetchSearchResults(searchReq);
    } else {
      //   toast.error('No search criteria provided.');
    }
  }, [searchReq]);

  const fetchSearchResults = async (data: getVendorListingReqInterface) => {
    const result: any = await getVenorListingFunc(data);
    if (result === null) {
      toast.error('Failed to filter data.');
      return;
    }
    if (result?.status_code === 200 && result?.data) {
      let dataObject: any = {
        vendor_list: [
          {
            title: `${result?.data?.total}`,
            data: result?.data?.data,
          },
        ],
      };
      setDashboardData(dataObject);
    } else {
      let dataObject: any = {
        vendor_list: [
          {
            title: `0`,
            data: [],
          },
        ],
      };
      setDashboardData(dataObject);
    }
  };

  const backToHome = () => {
    setDashboardData(null);
    setMapData(null);
    navigate('/home');
    setTimeout(() => {
      const section = document.getElementById('height');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Runs on component mount

  /* Map Code */
  //const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const [selectedCategory, setSelectedCategory] = useState(
    searchReq?.category_id || '',
  );
  const [genderBy, setGenderBy] = useState(searchReq?.gender_type || '');
  const [nearBy, setNearBy] = useState(searchReq?.sort_by || '');
  const [priceRange, setPriceRange] = useState<number[]>([0, 25000]);

  const [catdata, setCatData] = useState<CategoryList[]>([]);
  const [categoryData, setCategoryData] = useState<CategoryList[]>([]);

  const [locationAllowed, setLocationAllowed] = useState<boolean | null>(null);
  useEffect(() => {
    navigator.permissions
      .query({ name: 'geolocation' })
      .then(result => {
        setLocationAllowed(result.state === 'granted');

        result.onchange = () => {
          setLocationAllowed(result.state === 'granted');
        };
      })
      .catch(() => setLocationAllowed(false));
  }, []);

  const apiBaseUrl =
    process.env.REACT_APP_API_BASE_URL + '/get-categories?use_for=2';
  const vendorListingFilter22 = useCallback(
    async (data: getVendorListingReqInterface) => {
      try {
        const updatedData = {
          ...data,
          category_id: selectedCategory || 0,
          gender_type: genderBy ? parseInt(genderBy) : 0, // Gender filter
          //  latitude: nearBy == '3' ? 23.0248008 : 0, // Example latitude for 'Nearest' option
          //  longitude: nearBy == '3' ? 72.4762654 : 0, // Example longitude for 'Nearest' option
          //  min_price: priceRange[0],
          //  max_price: priceRange[1],
          sort_by: nearBy,
          service_percentage: '', // Price range filter
        };

        const result: any = await getVenorListingFunc(updatedData);
        if (result === null) {
          toast.error('Failed to filter data.');
          return;
        }
        if (result?.status_code === 200 && result?.data) {
          const dataObject = {
            vendor_list: [{ data: result?.data?.data }],
          };
          if (JSON.stringify(mapData) !== JSON.stringify(dataObject)) {
            setMapData(dataObject); // Update only if data is new
          }
        } else {
          toast.error(result?.message);
        }
      } catch (error) {
        console.error('Error filtering vendor list:', error);
      }
    },
    [
      getVenorListingFunc,
      selectedCategory,
      mapData,
      genderBy,
      nearBy,
      priceRange[0],
      priceRange[1],
    ],
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(apiBaseUrl);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setCatData(result.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []); // Run this only once on mount

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (locationAllowed === true) {
      const timer = setTimeout(() => {
        setIsLoading(false); // Hide loader after 5 seconds
      }, 2000);
      return () => clearTimeout(timer); // Cleanup the timer
    } else {
      const timer = setTimeout(() => {
        setIsLoading(false); // Hide loader after 5 seconds
      }, 3000);
      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [isLoading]);

  /* Map Code */
  if (isLoading) {
    return (
      <div className="loaderCcontainer">
        <img src={images.loader} />
      </div>
    );
  } else {
    return (
      <Box className="search">
        <Grid container spacing={2} className="grid-container">
          <Grid
            item
            xs={12}
            md={4.5}
            xl={4}
            sm={12}
            lg={4}
            className="scrollableLeft"
          >
            <div className="searchResultContainer">
              <div className="backButton">
                <img onClick={backToHome} src={icons.left} alt="home" />
              </div>
              <div className="resultMessage">
                We have found <span>{totalRecords}</span> search results around
                your location
              </div>
            </div>
            <div className="listTitle">
              <h1>{title || ''}</h1>
            </div>

            {loading ? (
              <div className="loaderCcontainer">
                <img src={images.loader} />
              </div>
            ) : customerDashboardListing.length > 0 ? (
              <ListSearchItems
                dataForPagination={customerDashboardListing}
                setPlotData={setPlotData}
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
              />
            ) : (
              <p>No records found</p>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={7.5}
            sm={12}
            lg={8}
            xl={8}
            className="mapRight"
          >
            <ListingFilters onApplyFilters={handleApplyFilters} />
            {/* <Map itemData={memoizedItemData} /> */}
            <Map itemData={plotData} />
          </Grid>
        </Grid>
      </Box>
    );
  }
};
export default List;
