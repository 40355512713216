import React, { useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import './about.css';
import { icons } from '../../../Assets/icons';
import { GoogleMap, LoadScriptNext, Marker } from '@react-google-maps/api';

type AboutProps = {
  title: string;
  description: string;
  address: string;
  nearbyAreas: string;
  latLong: { lat: number; lng: number };
};

const responsiveFontSizes = {
  xs: '0.8rem',
  sm: '1rem',
  md: '14px',
  lg: '16px',
  xl: '1.5rem',
};

const mapContainerStyle = {
  width: '100%',
  height: '100%',
};

const About: React.FC<AboutProps> = ({
  title,
  description,
  address,
  latLong,
}) => {
  const [iconOptions, setIconOptions] = React.useState<any>(null);

  const onMapLoad = () => {
    // Access the google.maps object here
    if (window.google) {
      setIconOptions({
        url: icons.marker,
        scaledSize: new window.google.maps.Size(40, 40),
        labelOrigin: new window.google.maps.Point(20, -40),
      });
    }
  };

  const handleMarkerClick = () => {
    const mapsUrl = `https://www.google.com/maps?q=${latLong.lat},${latLong.lng}`;
    window.open(mapsUrl, '_blank');
  };

  return (
    <Box className="about-section">
      <Grid container spacing={5} className="about-container">
        <Grid item xs={12} md={6}>
          <Box className="about-content">
            <Typography variant="h2" className="about-title">
              About
            </Typography>
            <Typography
              variant="h6"
              className="about-description"
              sx={{ fontSize: responsiveFontSizes }}
            >
              {description ||
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."}
            </Typography>
            {address && (
              <Box className="about-address-container">
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                    address?.replace(/,$/, '') || '',
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="google-maps-link"
                >
                  <Typography variant="body1" className="about-address">
                    {address?.replace(/,$/, '')}
                  </Typography>
                </a>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                    address?.replace(/,$/, '') || '',
                  )}`}
                  target="_blank"
                >
                  <img
                    loading="lazy"
                    src={icons.location}
                    alt=""
                    className="about-icon"
                  />
                </a>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid className="about-map" item xs={12} md={6}>
          {latLong && process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? (
            <LoadScriptNext
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            >
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={10}
                center={latLong}
                onLoad={onMapLoad}                
              >
                {iconOptions && (
                  <Marker
                    position={latLong}
                    icon={iconOptions}
                    label={{
                      text: title,
                      color: 'black',
                      fontSize: '10px',
                      fontWeight: 'normal',
                      className: 'aboutMarkerLabel',
                    }}
                    onClick={handleMarkerClick}
                  />
                )}
              </GoogleMap>
            </LoadScriptNext>
          ) : (
            <Typography variant="body1" color="textSecondary">
              Unable to load the map. Please check the location details.
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default About;
