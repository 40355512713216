import facebook from './facebook.svg';
import google from './google.svg';
import back from './back.svg';
import black from './Black.svg';
import blackRightArrow from './blackRightArrow.svg';
import white from './White.svg';
import check from './check.svg';
import switchbox from './switch.svg';
import pwdBar from './pwdBar.svg';
import forgotBar from './forgotBar.svg';
import businessBar from './businessBar.svg';
import hairSaloon from './hairSaloon.svg';
import nailSaloon from './nailSaloon.svg';
import eyeBrow from './eyeBrow.svg';
import barber from './barber.svg';
import other from './other.svg';
import otherBlue from './otherBlue.svg';
import skinCare from './skinCare.svg';
import aestheticMedicine from './aestheticMedicine.svg';
import aboutBar from './aboutBar.svg';
import india from './india.svg';
import uk from './UK.svg';
import payVenue from './payVenue.svg';
import callNow from './CallNow.svg';
import us from './US.svg';
import profilePlusAddress from './profilePlusAddress.svg';
import workBar from './workBar.svg';
import addressBar from './addressBar.svg';
import confirmBar from './confirmBar.svg';
import workLocation from './workLocationBar.svg';
import search from './searchAddress.svg';
import teamBar from './teamBar.svg';
import hoursArrow from './hoursArrow.svg';
import serviceBar from './serviceBar.svg';
import deleteService from './deleteService.svg';
import noImage from './noImg.svg';
import helpBar from './helpBar.svg';
import previousBar from './previousBar.svg';
import calender from './calender.svg';
import dashboard from './dashboard.svg';
import dashboardActive from './dashboardActive.svg';
import salesReport from './salesReport.svg';
import customerList from './customerList.svg';
import whiteCalender from './whiteCalender.svg';
import contactList from './contactList.svg';
import paymentDetails from './paymentDetails.svg';
import teamMembers from './teamMembers.svg';
import settings from './settings.svg';
import notification from './notification.svg';
import help from './help.svg';
import home from './Home.svg';
import homeBlue from './homeBlue.svg';
import work from './work.svg';
import workBlue from './workBlue.svg';
import userImage from './userImage.svg';
import heart from './heart.svg';
import service from './services.svg';
import anyDate from './anyDate.svg';
import location from './location.svg';
import rating from './rating.svg';
import recommendedSearch from './recommendedSearch.svg';
import redHeart from './redHeart.svg';
import bigRedHeart from './bigRedHeart.svg';
import apple from './apple.svg';
import googledownload from './googleDownload.svg';
import qrCode from './qrCode.svg';
import footerFacebook from './footerFacebook.svg';
import instagram from './instagram.svg';
import linkedIn from './linkedIn.svg';
import twitter from './twitter.svg';
import footer_fb from './footer_fb.svg';
import left from './left.svg';
import right from './right.svg';
import hamburger from './hamburger.svg';
import upload from './upload.svg';
import like from './like.svg';
import shopLocation from './shopLocation.svg';
import rightTick from './rightTick.svg';
import photos from './photos.svg';
import cv from './CandV.svg';
import time from './time.svg';
import shopInstagram from './shopInstagram.svg';
import shopFacebook from './shopFacebook.svg';
import shopTwitter from './shopTwitter.svg';
import shopGlobe from './shopGlobe.svg';
import shopYoutube from './shopYoutube.svg';
import profilePhoto from './profilePhoto.svg';
import settingsUpload from './uploadSvg.svg';
import filter from './filter.svg';
import downArrow from './downArrow.svg';
import serviceHelp from './serviceHelp.svg';
import jackLion from './jackLion.svg';
import laraRay from './laraRay.svg';
import lorenJam from './lorenJem.svg';
import franklinJobs from './franklinJobs.svg';
import micheDom from './michleDom.svg';
import jerry from './jerry.svg';
import edit from './edit.svg';
import teamCalender from './teamCalender.svg';
import calenderEdit from './calenderEdit.svg';
import timer from './timer.svg';
import leftArrow from './leftArrow.svg';
import xlsx from './xls.svg';
import pdf from './pdf.svg';
import threeDots from './threeDots.svg';
import updownArrows from './upDownArrows.svg';
import downloadPDF from './downloadPdf.svg';
import yellowLocation from './yellowLocation.svg';
import professional from './professional.svg';
import camera from './camera.svg';
import profilePlus from './profilePlus.svg';
import logout from './logout.svg';
import cancel from './cancel.svg';
import blueTick from './blueTick.svg';
import users from './users.svg';
import usersPlus from './usersPlus.svg';
import teamLeftArrow from './teamLeftArrow.svg';
import rightArrow from './rightArrow.svg';
import appointmentHelp from './appointmentHelp.svg';
import appointmentReview from './appointmentReview.svg';
import upcomingCalender from './upcomingCalender.svg';
import upcomingLocation from './upcomingLocation.svg';
import upcomingManage from './upcomingManage.svg';
import upcomingVenue from './upcomingVenue.svg';
import noAppointment from './noAppointment.svg';
import qrCustomers from './qrCustomers.svg';
import guest from './guest.svg';
import coupen from './coupen.svg';
import coupenArrow from './coupenArrow.svg';
import bookingLeft from './bookingLeft.svg';
import bookingRight from './bookingRight.svg';
import bookingPurpleTick from './bookingPurpleTick.svg';
import apptconf from './ApptConf.svg';
import offer from './offer.svg';
import calenderTeam from './calenderTeam.svg';
import close from './close.svg';
import payonline from './payOnline.svg';
import fb from './fb.svg';
import insta from './insta.svg';
import linkedin from './in.svg';
import homeFilter from './homeFilter.svg';
import footerLocation from './footerLocation.svg';
import sms from './sms.svg';
import supportHalfCircle from './supportHalfCircle.svg';
import supportfullCircle from './supportFullCircle.svg';
import seeAll from './seeAll.svg';
import x from './x.svg';
import youtube from './youtube.svg';
import marker from './marker.svg';
import financialReporting from './financialReporting.svg';
import stockManagement from './stockManagement.svg';
import salesPromotion from './salesPromotion.svg';
import checkoutSystsem from './checkoutSystem.svg';
import processPayment from './processPayment.svg';
import scheduleAddpointment from './scheduleAppointments.svg';
import businessCheck from './businessCheck.svg';
import confirmImage from './confirmImage.svg';
import rightPurple from './rightPurple.svg';
import glam9NewLogo from './glam9NewLogo.svg';
import whiteRightArrow from './whiteRightArrow.svg';
import glam9WhiteLogo from './glam9WhiteLogo.svg';
import linkedinIcon from './linkedinIcon.svg';
import playstore from './playStore.svg';
import appstore from './appStore.svg';
import loginLogo from './loginLogo.svg';
import purpleBack from './purpleBack.svg';
import contactLocation from './contactLocation.svg';
import contactPhone from './contactPhone.svg';
import contactMail from './contactMail.svg';
import indiaFlag from './indiaFlag.svg'
import luxuryIcon from './luxuryIcon.svg'
import shareIcon from './shareIcon.svg'
import locationBlack from './locationBlack.svg'
import infoIcon from './infoIcon.svg'
import grayStart from './grayStart.svg'
import grayEnd from './grayEnd.svg'
import newLeft from './newLeft.svg'
import newRight from './newRight.svg'
export const icons = {
  facebook,
  google,
  back,
  marker,
  black,
  blackRightArrow,
  homeFilter,
  footerLocation,
  sms,
  supportHalfCircle,
  supportfullCircle,
  white,
  check,
  coupen,
  callNow,
  offer,
  seeAll,
  coupenArrow,
  switchbox,
  calenderTeam,
  close,
  payonline,
  footer_fb,
  usersPlus,
  other,
  otherBlue,
  profilePlusAddress,
  guest,
  qrCustomers,
  appointmentHelp,
  appointmentReview,
  redHeart,
  bigRedHeart,
  noImage,
  upcomingCalender,
  upcomingLocation,
  upcomingManage,
  upcomingVenue,
  noAppointment,
  camera,
  profilePlus,
  professional,
  apptconf,
  pwdBar,
  forgotBar,
  rightTick,
  businessBar,
  payVenue,
  hairSaloon,
  nailSaloon,
  yellowLocation,
  whiteCalender,
  teamLeftArrow,
  rightArrow,
  eyeBrow,
  barber,
  users,
  skinCare,
  aestheticMedicine,
  aboutBar,
  india,
  blueTick,
  uk,
  us,
  heart,
  workBar,
  addressBar,
  search,
  confirmBar,
  workLocation,
  teamBar,
  hoursArrow,
  serviceBar,
  deleteService,
  helpBar,
  home,
  homeBlue,
  work,
  workBlue,
  previousBar,
  dashboard,
  dashboardActive,
  calender,
  salesReport,
  customerList,
  contactList,
  paymentDetails,
  teamMembers,
  settings,
  notification,
  help,
  userImage,
  service,
  location,
  anyDate,
  rating,
  cancel,
  recommendedSearch,
  apple,
  qrCode,
  googledownload,
  footerFacebook,
  instagram,
  linkedIn,
  twitter,
  left,
  leftArrow,
  right,
  hamburger,
  upload,
  like,
  shopLocation,
  photos,
  cv,
  time,
  shopInstagram,
  shopFacebook,
  shopGlobe,
  shopTwitter,
  shopYoutube,
  profilePhoto,
  settingsUpload,
  filter,
  downArrow,
  serviceHelp,
  jackLion,
  laraRay,
  lorenJam,
  franklinJobs,
  logout,
  micheDom,
  jerry,
  edit,
  calenderEdit,
  teamCalender,
  timer,
  xlsx,
  pdf,
  threeDots,
  updownArrows,
  downloadPDF,
  bookingLeft,
  bookingRight,
  bookingPurpleTick,
  fb,
  insta,
  linkedin,
  x,
  youtube,
  financialReporting,
  salesPromotion,
  stockManagement,
  checkoutSystsem,
  processPayment,
  scheduleAddpointment,
  businessCheck,
  confirmImage,
  rightPurple,
  glam9NewLogo,
  whiteRightArrow,
  glam9WhiteLogo,
  linkedinIcon,
  loginLogo,
  purpleBack,
  contactLocation,
  contactPhone,
  contactMail,
  indiaFlag,
  luxuryIcon,
  shareIcon,
  locationBlack,
  playstore,
  appstore,
  infoIcon,
  grayEnd,
  grayStart,
  newLeft,
  newRight,
};
