import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, Box, Button } from '@mui/material';
import { images } from '../../../../src/Assets/images';
import { icons } from '../../../../src/Assets/icons';
import './Offersection.css';
import { useNavigate } from 'react-router-dom';

type OfferSectionProps = {
  data: any; // Accept any type of data
  type:string;
};

const Offersection: React.FC<OfferSectionProps> = ({ data, type }) => {
  const navigate = useNavigate();

  const goToVendor = (id: any) => {
    localStorage.removeItem('shopID');
    localStorage.setItem('shopID', id);
    navigate('/shop');
  };

  

  return (
    <>
      <Box
        className="offerSection"
        sx={{
          position: 'relative',
          overflow: 'hidden',
          '&:hover .overlay': {
            opacity: 1,
          },
          '&:hover img.mainImage': {
            filter: 'blur(5px)', // Adjust the blur radius as needed
            transition: 'filter 0.3s ease-in-out', // Smooth blur effect
          },
        }}
      >
        <img
          src={data.media_name}
          className="mainImage"
          alt="Korean Class Skin Treatment"
          style={{ width: '100%', height: 'auto' }}
        />
        <Box
          className="overlay"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: 'calc(100% - 7px)',
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '10px',
            opacity: 0,
            transition: 'opacity 0.3s ease-in-out',
          }}
        >
          {/* <Button
      variant="contained"
      className='bookNow'
      sx={{background:'#fff'}}
    >
      BOOK NOW <img src={icons.rightPurple} />
    </Button> */}
          <Button
            variant="contained"
            className="viewSalon"
            sx={{ background: 'transparent' }}
            onClick={() => goToVendor(data.vendor_detail_id)}
          >
            VIEWS {type} DETAILS <img src={icons.whiteRightArrow} />
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Offersection;
