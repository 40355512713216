import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Grid,
} from '@mui/material';
import AuthService from '../../Services/auth.service';
import { useNavigate, Link } from 'react-router-dom';
import BeautySpot from './BeautySpot';
import SearchItems from './SearchItems';
import { Map } from './Map';
import toast from 'react-hot-toast';
import { images } from '../../../src/Assets/images';
import { icons } from '../../Assets/icons';
import { getVendorListingReqInterface } from '../../../src/api/types/dashboard';
import useAppContext from '../../../src/hooks/useAppContext';
import './Search.css';
/* Map Import  */
import './Map.css'; // Importing the external CSS for styling
import Slider from '@mui/material/Slider';

interface CategoryList {
  id: number;
  name: string;
  logo_link: string;
  sequence: number;
  is_premium_category: number;
}
interface MapProps {
  itemData: any; // Replace `any` with the actual type of `itemData` for better type safety
}
interface MapData {
  vendor_list: { data: any }[];
}
/* Map Import  */
const Search = () => {
  const authService = AuthService();
  const navigate = useNavigate();
  const {
    getCityListFunc,
    getBannerImagesFunc,
    getDropdownListFunc,
    getDashboardDataFunc,
    getCategoryListFunc,
    getVenorListingFunc,
  } = useAppContext();
  const [dashboardData, setDashboardData] = useState<any>(null);
  const [mapData, setMapData] = useState<MapData | null>(null);
  const [plotData, setPlotData] = useState([]);
  const location = useLocation();
  
  useEffect(() => {
    document.body.classList.add('noScroll');
    return () => {
      document.body.classList.remove('noScroll');
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const searchReq = location.state?.searchReq;
  const filterURL = process.env.REACT_APP_API_BASE_URL + '/get-vendor-listing';
  const searchData = location.state?.data;

  useEffect(() => {
    if (searchReq?.latitude && searchReq?.longitude) {
      setMapData((prevMapData) => ({
        ...prevMapData,
        vendor_list: prevMapData?.vendor_list || [],
        latitude: searchReq.latitude,
        longitude: searchReq.longitude,
      }));
    }
  }, [searchReq]);

  // Used one Data array from the all types of data coming
  let dataForPagination =
    mapData?.vendor_list[0]?.data ||
    dashboardData?.vendor_list[0]?.data ||
    searchData?.data ||
    [];


  



  useEffect(() => {
    if (searchReq) {
      fetchSearchResults(searchReq);
    } else {
      //   toast.error('No search criteria provided.');
    }
  }, [searchReq]);

  const fetchSearchResults = async (data: getVendorListingReqInterface) => {
    const result: any = await getVenorListingFunc(data);
    if (result === null) {
      toast.error('Failed to filter data.');
      return;
    }
    if (result?.status_code === 200 && result?.data) {
      let dataObject: any = {
        vendor_list: [
          {
            title: `${result?.data?.total}`,
            data: result?.data?.data,
          },
        ],
      };
      setDashboardData(dataObject);
    } else {
      let dataObject: any = {
        vendor_list: [
          {
            title: `0`,
            data: [],
          },
        ],
      };
      setDashboardData(dataObject);
    }
  };

  /* function backToHome() {
    
  } */

  const backToHome = () => {
    setDashboardData(null);
    setMapData(null);
    navigate('/home');
    setTimeout(() => {
      const section = document.getElementById("height");
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Runs on component mount

  const memoizedItemData = useMemo(() => {
    /* if (
      !dashboardData ||
      !dashboardData.vendor_list ||
      !dashboardData.vendor_list[0] 
    ) {
      return [];
    } */

    /* if(!searchData || !searchData.data || !searchData?.data ){
      return [];
    } */
    if (dashboardData?.vendor_list?.[0]) {
    }

    // Fallback to dashboardData if searchData is not available
    return (
      mapData?.vendor_list[0]?.data ||
      searchData?.data ||
      dashboardData?.vendor_list?.[0]?.data ||
      []
    );
  }, [dashboardData, searchData, mapData]);

  /* Map Code */
  //const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const [selectedCategory, setSelectedCategory] = useState(
    searchReq?.category_id || '',
  );
  const [genderBy, setGenderBy] = useState(searchReq?.gender_type || '');
  const [nearBy, setNearBy] = useState(searchReq?.sort_by || '');
  const [priceRange, setPriceRange] = useState<number[]>([0, 25000]);

  const [error, setError] = useState<boolean>(false);

  //check data for beuty saloon
  const newMapDataForSaloon = mapData?.vendor_list[0]?.data;

  const [dashboardDataReq, setDashboardDataReq] =
    useState<getVendorListingReqInterface>({
      search_keyword: searchReq?.search_keyword,
      sort_by: 0,
      salon_type: searchReq?.salon_type,
      category_id: 0,
      professional_keyword: '',
      gender_type: 0,
      min_price: 0,
      max_price: 0,
      latitude: searchReq?.latitude,
      longitude: searchReq?.longitude,
    });

  const handleCategoryChange = (event: any) => {
    setSelectedCategory(event.target.value);
    setSliderDisabled(false);
    if(event.target.value == ''){
      setPriceRange([0, 25000]);
    }
  };

  const handleGenderChange = (event: any) => {
    setGenderBy(event.target.value); // Update state when an option is selected
  };

  const handleNearBy = (event: any) => {
    setNearBy(event.target.value); // Update state when an option is selected
  };

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    if (!selectedCategory) {
      return;
    }

    setPriceRange(newValue as number[]);
    // Use the newValue directly to update the dashboardDataReq
    setDashboardDataReq(prevState => ({
      ...prevState,
      min_price: (newValue as number[])[0],
      max_price: (newValue as number[])[1],
    }));
  };

  const handleMouseDown = () => {
    if (!selectedCategory) {
      toast.error('Please select a category first.');
      setSliderDisabled(true);
    }
  };

  const clearFilters = () => {
    setGenderBy('');
    setNearBy('');
    setSelectedCategory('');
    setPriceRange([0, 25000]);
  };

  const minMarks = [
    {
      value: 0,
      label: '₹0',
    },
    {
      value: 25000,
      label: '₹25000',
    },
  ];
  const [catdata, setCatData] = useState<CategoryList[]>([]);
  const [categoryData, setCategoryData] = useState<CategoryList[]>([]);

 const [locationAllowed, setLocationAllowed] = useState<boolean | null>(null);  
 useEffect(() => {
     navigator.permissions
       .query({ name: "geolocation" })
       .then((result) => {
         setLocationAllowed(result.state === "granted");
 
         result.onchange = () => {
           setLocationAllowed(result.state === "granted");
         };
       })
       .catch(() => setLocationAllowed(false)); 
   }, []); 

  const [sliderDisabled, setSliderDisabled] = useState(false);
  const apiBaseUrl =
    process.env.REACT_APP_API_BASE_URL + '/get-categories?use_for=2';
  const vendorListingFilter22 = useCallback(
    async (data: getVendorListingReqInterface) => {

      try {
        const updatedData = {
          ...data,
          category_id: selectedCategory || 0,
          gender_type: genderBy ? parseInt(genderBy) : 0, // Gender filter
        //  latitude: nearBy == '3' ? 23.0248008 : 0, // Example latitude for 'Nearest' option
        //  longitude: nearBy == '3' ? 72.4762654 : 0, // Example longitude for 'Nearest' option
          //  min_price: priceRange[0],
          //  max_price: priceRange[1],
          sort_by: nearBy,
          service_percentage: '', // Price range filter
        };
        
        const result: any = await getVenorListingFunc(updatedData);
        if (result === null) {
          toast.error('Failed to filter data.');
          return;
        }
        if (result?.status_code === 200 && result?.data) {
          const dataObject = {
            vendor_list: [{ data: result?.data?.data }],
          };
          if (JSON.stringify(mapData) !== JSON.stringify(dataObject)) {
            setMapData(dataObject); // Update only if data is new
          }
        } else {
          toast.error(result?.message);
        }
      } catch (error) {
        console.error('Error filtering vendor list:', error);
      }
    },
    [
      getVenorListingFunc,
      selectedCategory,
      mapData,
      genderBy,
      nearBy,
      priceRange[0],
      priceRange[1],
    ],
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(apiBaseUrl);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setCatData(result.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []); // Run this only once on mount

  const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
      if(locationAllowed === true){
        const timer = setTimeout(() => {
          setIsLoading(false); // Hide loader after 5 seconds
        }, 2000);
        return () => clearTimeout(timer); // Cleanup the timer
      }else{
        const timer = setTimeout(() => {
        setIsLoading(false); // Hide loader after 5 seconds
        }, 3000);
        return () => clearTimeout(timer); // Cleanup the timer
      }
      
    }, [isLoading]);

  /* Map Code */
  if (isLoading) {
      return (
        <div className="loaderCcontainer">
          <img src={images.loader} />
        </div>
      );
    } else {
  return (
    <Box className="search">
      <Grid container spacing={2} className="grid-container">
        <Grid item xs={12} md={4.5} xl={4} sm={12} lg={4} className="scrollableLeft" >
          <div className="searchResultContainer">
            <div className="backButton">
              <img onClick={backToHome} src={icons.left} alt="home" />
            </div>
            <div className="resultMessage">
              We have found{' '}
              <span>
                {mapData?.vendor_list[0]?.data.length ||
                  dashboardData?.vendor_list[0].title ||
                  searchData?.data.length}
              </span>{' '}
              search results around your location
            </div>
          </div>
          <SearchItems dataForPagination={dataForPagination} setPlotData={setPlotData}/>
        </Grid>
        <Grid item xs={12} md={7.5} sm={12} lg={8} xl={8} className="mapRight">
          <Grid container spacing={2} className="search-grid">
            <Grid className="nearByFilter" item xs={12} md={4} xl={2} lg={2}>
              <FormControl variant="outlined" fullWidth className="sortBylocation" >
                <Select
                  value={nearBy}
                  onChange={handleNearBy}
                  displayEmpty
                  className="filterCategory"
                  MenuProps={{
                    disableScrollLock: true,
                    PaperProps: {
                      style: { maxHeight: 200 },
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>Sort By</em>
                  </MenuItem>{' '}
                  {/* Add a placeholder */}
                  <MenuItem value="1">Top Rated</MenuItem>
                  <MenuItem value="3">Nearest</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid className="filterPrice" item xs={12} md={4} xl={2} lg={2}>
              <>
                <Box>
                  <Slider
                    getAriaLabel={() => 'Price range'}
                    value={priceRange}
                    onChange={handleSliderChange}
                    onMouseDown={handleMouseDown}
                    valueLabelDisplay="auto"
                    marks={minMarks}
                    min={0}
                    max={25000}
                    getAriaValueText={(value: any) => `${value}`}
                    disabled={sliderDisabled}
                  />
                </Box>
              </>
            </Grid>
            <Grid className="genderFilter" item xs={12} md={4} xl={2} lg={2}>
              <FormControl variant="outlined" fullWidth className="sortByGender" >
                <Select labelId="demo-simple-select-label" value={genderBy} displayEmpty onChange={handleGenderChange} >
                  <MenuItem value=""><em>Gender Type</em></MenuItem>
                  <MenuItem value="1">Male</MenuItem>
                  <MenuItem value="2">Female</MenuItem>
                  <MenuItem value="3">Unisex</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid className="categoryFilter" item xs={12} md={4} xl={2} lg={2}>
              <FormControl variant="outlined" fullWidth>
                <Select
                  labelId="category-select-label"
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  className="filterCategory"
                  displayEmpty
                  MenuProps={{
                    disableScrollLock: true,
                    PaperProps: {
                      style: { maxHeight: 200 },
                    },
                  }}
                >
                  <MenuItem value=""><em>Category</em></MenuItem>
                  {catdata.length > 0 ? (
                    catdata.map(row => (
                      <MenuItem key={row.id} value={row.id}>
                        {row.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No categories available</MenuItem> // Fallback UI when data is empty
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid className="searchFilter" item xs={12} md={4} xl={2} lg={2}>
              <Button
                onClick={() =>
                  vendorListingFilter22({
                    ...dashboardDataReq,
                    //  service_percentage: `${priceRange[0]}-${priceRange[1]}`, // Use updated price range directly
                  })
                }
                className="mapSearch"
              >
                Search
              </Button>
            </Grid>
            <Grid className="clearFilter" item xs={12} md={4} xl={2} lg={2}>
              <Button onClick={() => clearFilters()} className="mapSearch">
                Clear
              </Button>
            </Grid>
          </Grid>
          {/* <Map itemData={memoizedItemData} /> */}
          <Map itemData={plotData} />
        </Grid>
      </Grid>
    </Box>
  );
};
}
export default Search;