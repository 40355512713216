import React from 'react';
import { Box, Typography, Button, Divider } from '@mui/material';
import './SocialLoginsSection.css';

interface SocialLoginProps {
  name: string;
  icon: string;
  isConnected: boolean;
}

const SocialLogin: React.FC<SocialLoginProps> = ({
  name,
  icon,
  isConnected,
}) => {
  return (
    <Box className="social-login-container">
      <Box className="social-login-details">
        <img loading="lazy" src={icon} className="social-login-icon" alt={`${name} icon`} />
        <Typography className="social-login-name">{name}</Typography>
      </Box>
      <Button variant="outlined" className={`social-login-button ${isConnected ? 'disconnect' : 'connect'}`} >{isConnected ? 'Disconnect' : 'Connect'}</Button>
    </Box>
  );
};
const SocialLoginsSection: React.FC = () => {
  const socialLogins = [
    {
      name: 'Facebook',
      icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/b2912a12a0f593febf43ced09c7e81133fa85aaccb63a2a052a4fdbe6558cddd?placeholderIfAbsent=true&apiKey=4c2888e9b8b84b548fb4bc99e165dd13',
      isConnected: false,
    },
    {
      name: 'Google',
      icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/94b0a640ba587fb51b071167fbb07d0d72b908de036811fb1244e6d0229044fa?placeholderIfAbsent=true&apiKey=4c2888e9b8b84b548fb4bc99e165dd13',
      isConnected: true,
    },
  ];
  return (
    <Box className="social-logins-section">
      <Typography variant="h5" className="section-title">My Social Logins</Typography>
      <Typography variant="body1" className="section-description">Link social profiles for easier access to your Glam9 account.</Typography>
      {socialLogins.map((login, index) => (
        <React.Fragment key={login.name}>
          <SocialLogin {...login} />
          {index < socialLogins.length - 1 && (
            <Divider className="social-login-divider" />
          )}
        </React.Fragment>
      ))}
    </Box>
  );
};
export default SocialLoginsSection;