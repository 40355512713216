// src/api/userApi.ts

import { apiClient } from './apiClient';
import { ApiResponseFromAPI } from './types/common';
import { getVendorListingReqInterface } from './types/dashboard';

export const getDashboardDataListing = (
  filter_type: string,
  page: string,
  limit: string,
  options: {
    lattitude?: string;
    longitude?: string;
    nearest?: string;
    radius?: string;
    rates?: string;
    min_price?: string;
    max_price?: string;
  } = {}
) => {
  // Start building the query string with mandatory parameters
  let queryString = `filter_type=${encodeURIComponent(filter_type)}&page=${encodeURIComponent(page)}&limit=${encodeURIComponent(limit)}`;

  // Dynamically add optional parameters if they exist
  const optionalParams = {
    lattitude: options.lattitude,
    longitude: options.longitude,
    nearest: options.nearest,
    radius: options.radius,
    rates: options.rates,
    min_price: options.min_price,
    max_price: options.max_price,
  };
  

  for (const [key, value] of Object.entries(optionalParams)) {
    if (value !== undefined && value !== null) {
      
      queryString += `&${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    }
  }

  // Construct the full API URL
  const url = `/customer-dashboard-listing?${queryString}`;

  // Make the API call
  return apiClient<ApiResponseFromAPI>(url, { method: 'GET' });
};


// Get Dashboard Data
// export const getDashboardDataListing = (filter_type: string,lat : string,long : string,nearest: string,radius: string,rates:string,min_price: string,max_price: string,page: string,limit: string) => {
//   return apiClient<ApiResponseFromAPI>(
//     `/customer-dashboard-listing?filter_type=${filter_type}&lattitude=${lat}&longitude=${long}&nearest=${nearest}&radius=${radius}&rates=${rates}&min_price=${min_price}&max_price=${max_price}&page=${page}&limit=${limit}`,
//     { method: 'GET' },
//   );
// };

// Get Dashboard Data
export const getDashboardData = (lat : number,long : number) => {
  return apiClient<ApiResponseFromAPI>(
    `/customer-dashboard?lattitude=${lat}&longitude=${long}`,
    { method: 'GET' },
  );
};

// Get Banner Images
export const getBannerImages = () => {
  return apiClient<any>(`/get-banners`, {
    method: 'GET',
  });
};

// Get Vendor Listing
export const getVendorListing = (data: getVendorListingReqInterface) => {
  return apiClient<ApiResponseFromAPI>(`/get-vendor-listing`, {
    method: 'POST',
    body: data,
  });
};

// Get Vendor Listing
export const getVendorOffers = () => {
  return apiClient<ApiResponseFromAPI>(`/get-offers`, {
    method: 'GET',
  });
};
