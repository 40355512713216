import React, { useEffect, useState } from 'react';
import ProfileSidebar from './profileSidebar';
import AddressSection from './addressSection';
import PaymentMethodsSection from './PaymentMethodsSection';
import SocialLoginsSection from './SocialLoginsSection';
import NotificationsSection from './NotificationsSection';
import DeleteAccountSection from './DeleteAccountSection';
import QuickLinksSection from './QuickLinksSection';
import { Container, Grid, Box } from '@mui/material';
import './ProfilePage.css';
import CommonCookie from '../CommonCookie';
import { useCookies } from 'react-cookie';
import { CustomerAPI } from '../../../src/hooks/customer';
import toast from 'react-hot-toast';
const ProfilePage = () => {
  const [customerData, setCustomerData] = useState<any>(null);
  const [cookies, , removeCookie] = useCookies([
    CommonCookie.token,
    CommonCookie.user,
    CommonCookie.userType,
    CommonCookie.profile,
    CommonCookie.vendor_id,
  ]);
  const { getCustomerProfileAPICall } = CustomerAPI();
  useEffect(() => {
    // let customerInfo = localStorage.getItem('customer_details');
    // if (customerInfo) {
    //   customerInfo = JSON.parse(customerInfo);
    //   setCustomerData(customerInfo);
    // }
    getCustomerProfileFunc();
  }, []);
  const getCustomerProfileFunc = async () => {
    const result: any = await getCustomerProfileAPICall();
    if (result === null) {
      toast.error('Failed to get customer profile.');
      return;
    }
    if (result?.status_code === 200) {
      setCustomerData(result.data);
    } else {
      toast.error(result?.message);
    }
  };
  return (
    <>
      <Container className="profile" component="main" maxWidth="lg" sx={{ mt: { xs: 3, md: 8 }, mb: 4 }} >
        <Grid container spacing={3}>
          <Grid className="sidebarContent" item xs={12} md={4}>
            {customerData && (
              <ProfileSidebar
                customerData={customerData}
                getCustomerData={getCustomerProfileFunc}
              />
            )}
          </Grid>
          <Grid className="mainContent" item xs={12} md={8}>
            <Box sx={{ mt: { xs: 3, md: 0 } }}>
              {customerData && (
                <AddressSection
                  customerData={customerData}
                  getCustomerData={getCustomerProfileFunc}
                />
              )}

              <PaymentMethodsSection />
              <SocialLoginsSection />
              <NotificationsSection />
              <DeleteAccountSection />
              <QuickLinksSection />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default ProfilePage;