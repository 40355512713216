import React, { useCallback, useEffect, useState } from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';
import Breadcrumb from './Breadcrumb';
import SalonCard from './salonCard';
import './FavoritesPage.css';
import { EmptyFavorites } from './EmptyFavorites';
import { ShopAPI } from '../../../src/hooks/shop';
import toast from 'react-hot-toast';
;

const FavoritesPage = () => {
  const [favList, setFavList] = useState<any>([]);
  const { getFavVendorListAPICall } = ShopAPI()
  const getFavList = useCallback(async () => {
    const result = await getFavVendorListAPICall();
    if (result && result.data) {
      setFavList(result.data)
    } else {
    }
  }, [getFavVendorListAPICall]);
  useEffect(() => {
    getFavList()
  }, [])
  return (
    <Box className="favorites-page">
      <Container maxWidth="xl">
        <Breadcrumb currentPage="Favourites" />
        <Typography variant="h4" component="h1" className="favorites-title">
          Favourites
        </Typography>
        {favList.length > 0 ? (
        <Box className="salon-list">
          <Grid container spacing={3}>
            {favList.map((salon: any, index: number) => (
              <Grid item xs={12} sm={6} md={6} lg={4} xl={4} key={index}>
                <SalonCard data={salon} />
              </Grid>
            ))}
          </Grid>
        </Box>
        ):(
          <EmptyFavorites  />
        )}   
      </Container>
    </Box>
  );
};

export default FavoritesPage;
