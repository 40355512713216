import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import './PaymentMethodsSection.css';
import { icons } from '../../Assets/icons';

const PaymentMethodsSection = () => {
  return (
    <Box className="payment-methods-section">
      <Typography variant="h5">My Payment Methods</Typography>
      <Typography className="description" variant="body1">
        Securely save your card details for hassle-free payments.
      </Typography>
      <Button className="add-payment-button">
        <img
          loading="lazy"
          src={icons.profilePlusAddress}
          className="payment-method-image"
          alt="Add Payment Method"
        />
        <span className="button-text">Add Payment Method</span>
      </Button>
    </Box>
  );
};

export default PaymentMethodsSection;
