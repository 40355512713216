import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { Service } from '../../../src/api/types/services';
import { icons } from '../../../src/Assets/icons';
import './Shop.css';

interface PackageProps {
  open: boolean;
  name?: string;
  description?: string;
  time?: string;
  price?: string;
  amountType?: string;
  actualAmount?: string;
  handleClose: () => void;
  services?: Service[];
}

const ViewPackageDetailsPopups: React.FC<PackageProps> = ({
  open,
  name,
  description,
  time,
  price,
  amountType,
  actualAmount,
  services,
  handleClose,
}) => {
  const [openModal, setOpenModal] = React.useState(open);
  const handleOpen = () => setOpenModal(true);

  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="packagePopup"
      >
        <Box className="popupContent">
          <div className='topSection'>
            <Typography id="modalModalTitle" >{name}</Typography>
            <Typography id="modalModalClose" onClick={handleClose} >
              {/* <img alt="Closing icon" src={icons.cancel} /> */}
              <svg onClick={handleClose} className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CloseIcon">
                <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
              </svg>
            </Typography>
          </div>
          <Typography className='description'>
            {description}
          </Typography>

          <Box sx={{ mt: 1 }}>
            <Typography className='processTime'>  
              Process Time : {time}, {services?.length} Services 
            </Typography>
            <Typography className='processPrice' >
              <span className='discountPrice'>{price ? `₹${Number(price).toFixed(2)}` : 'Free'}</span>
              {actualAmount != '' && (
              <span className='actualPrice'>₹{Number(actualAmount).toFixed(2)}</span>
              )}
            </Typography>
          </Box>
          <Typography className='whatsIncluded'>What's included</Typography>

          <Box sx={{ mt: 1 }}>
            {services?.map(item => {
              return (
                <Box sx={{ mt: 1 }}>
                  <Typography className='serviceName'>{item.service_name}</Typography>
                  <Typography className='serviceHour'>Process Time: {item.service_hour}hr</Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
export default ViewPackageDetailsPopups;