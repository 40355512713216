import React, { useState, useEffect } from 'react';
import { Box, Container, Grid, Typography, Divider } from '@mui/material';
import { useLocation, Link } from 'react-router-dom';
import { icons } from '../../../../src/Assets/icons';
import { images } from '../../../../src/Assets/images';
import './Footer.css';

const Footer = (): JSX.Element => {
  const [show, setShow] = useState(false);
  const location = useLocation();
  useEffect(() => {
    setShow(false);
    let currentPage = location.pathname.toString();
    let notAllowedPages: string[] = [
      '/',
      '/register',
      '/otp',
      '/search',
      '/list',
      '/reviews',
      '/appointment',
    ];
    //if (!notAllowedPages.some(page => currentPage.includes(page))) {
    if (!notAllowedPages.includes(currentPage)) {
      setShow(true);
    }
  }, [location]);

  const currentYear = new Date().getFullYear();

  return (
    <>
      {show && (
        <Box className="footerContainer">
          <Container className="footer">
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} className="footerBox">
                <img className="footerIcon" src={icons.glam9WhiteLogo} />
                <Box className="footerSocial">
                  <Link to={'https://www.facebook.com/glam9official'}>
                    <img src={icons.footer_fb} />
                  </Link>
                  <Link to={'https://www.instagram.com/glam9.beauty/'}>
                    <img src={icons.instagram} />
                  </Link>
                  <Link
                    to={
                      'https://www.linkedin.com/company/glam9-solutions-private-limited/'
                    }
                  >
                    <img src={icons.linkedinIcon} />
                  </Link>
                  <Link to={'https://x.com/glam9_beauty'}>
                    <img src={icons.twitter} />
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} className="footerBox">
                <Typography className="footerHeads">About Glam9</Typography>
                <Typography>
                  <Link to="/about">About Us</Link>
                </Typography>
                <Typography>
                  <Link to="/support">Contact Us</Link>
                </Typography>
                <Typography>
                  <Link to="https://blog.glam9.com">Blog</Link>
                </Typography>
                <Typography>
                  <Link to="https://career.glam9.com/">Career</Link>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2.5} className="footerBox">
                <Typography className="footerHeads">For Business</Typography>
                <Typography>
                  <Link to="/business">Service For Partners</Link>
                </Typography>
                <Typography>
                  <Link to="/pricing">Pricing</Link>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2.5} className="footerBox">
                <Typography className="footerHeads">Legal</Typography>
                <Typography className="footerContent">
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </Typography>
                <Typography>
                  <Link to="/terms-of-services">Terms of Services</Link>
                </Typography>
                <Typography>
                  <Link to="/refund">Refund & Cancellation Policy</Link>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={2.5}
                className="footerBox footerContactInfo"
              >
                <Typography className="footerHeads">
                  Contact Information
                </Typography>
                <Box className="footerAddressTop">
                  <img src={icons.footerLocation} alt="Location Icon" />
                  <Typography className="footerAddress">
                    Glam9 Solution Pvt. Ltd.{' '}
                    <span>205, Iscon Elegance, SG Highway,</span>{' '}
                    <span>Service Rd, Prahlad Nagar,</span> Ahmedabad, Gujarat
                    380015
                  </Typography>
                </Box>
                <Box className="footerAddressTop">
                  <img src={icons.sms} alt="Email Icon" />
                  <Typography>
                    <Link to="mailto:inquiry@glam9.com">inquiry@glam9.com</Link>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Divider
              className="footerDivider"
              sx={{ backgroundColor: '#454545', marginY: 4 }}
            />
            <Typography align="center" className="footerCopyWrite">
              Copyright © {currentYear.toString() || ''} Glam9 Solution Pvt.
              Ltd.
            </Typography>
          </Container>
        </Box>
      )}
    </>
  );
};

export default Footer;
