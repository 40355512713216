import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Slider,
} from '@mui/material';

interface FiltersProps {
  onApplyFilters: (filters: Record<string, any>) => void;
}

const ListingFilters: React.FC<FiltersProps> = ({ onApplyFilters }) => {
  const [nearest, setNearest] = useState(false);
  const [latitude, setLatitude] = useState<number | null>(null);
  const [longitude, setLongitude] = useState<number | null>(null);
  const [reviews, setReviews] = useState('');
  const [radius, setRadius] = useState('');
  const [priceRange, setPriceRange] = useState<number[]>([0, 25000]);

  // Old filter function
  // const handleNearestClick = () => {
  //   if (!nearest) {
  //     navigator.geolocation.getCurrentPosition(
  //       position => {
  //         setLatitude(position.coords.latitude);
  //         setLongitude(position.coords.longitude);
  //       },
  //       error => console.error('Error getting location:', error),
  //     );
  //   } else {
  //     setLatitude(null);
  //     setLongitude(null);
  //   }
  //   setNearest(!nearest);
  // };

  const getLocation = () => {
    navigator.geolocation.getCurrentPosition(
      position => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      },
      error => console.error('Error getting location:', error),
    );
  };

  // Handle "Nearest" selection
  const handleNearestClick = () => {
    const newNearest = !nearest;
    setNearest(newNearest);

    if (newNearest || radius) {
      getLocation(); // Fetch location when "Nearest" or "Radius" is selected
    } else {
      setLatitude(null);
      setLongitude(null);
    }
  };

  // Handle "Radius" selection
  const handleRadiusChange = (event: any) => {
    const selectedRadius = event.target.value;
    setRadius(selectedRadius);

    if (selectedRadius || nearest) {
      getLocation(); // Fetch location when "Nearest" or "Radius" is selected
    } else {
      setLatitude(null);
      setLongitude(null);
    }
  };

  // Apply selected filters
  const handleSearch = () => {
    const appliedFilters: Record<string, any> = {};
    // if (nearest && latitude && longitude) {
    //   appliedFilters.nearest = '1';
    //   appliedFilters.lattitude = latitude.toString();
    //   appliedFilters.longitude = longitude.toString();
    // }

    if (nearest) appliedFilters.nearest = '1';
    if ((nearest || radius) && latitude && longitude) {
      appliedFilters.lattitude = latitude.toString();
      appliedFilters.longitude = longitude.toString();
    }
    if (reviews) appliedFilters.rates = reviews;
    if (radius) appliedFilters.radius = radius;
    if (priceRange[0] !== 0 || priceRange[1] !== 25000) {
      appliedFilters.min_price = priceRange[0].toString();
      appliedFilters.max_price = priceRange[1].toString();
    }

    onApplyFilters(appliedFilters);
  };

  const handleClearFilters = () => {
    setNearest(false);
    setLatitude(null);
    setLongitude(null);
    setReviews('');
    setRadius('');
    setPriceRange([0, 25000]);

    // Pass an empty object to reset API filters
    onApplyFilters({});
  };

  const marks = [
    {
      value: 0,
      label: '₹0',
    },
    {
      value: 25000,
      label: '₹25000',
    },
  ];

  return (
    <>
      <Grid container spacing={2} className="search-grid">
        <Grid
          className="nearByFilter"
          item
          xs={12}
          md={4}
          xl={2}
          lg={2}
          // onClick={handleNearestClick}
        >
          <div
            className={`nearestFilter ${nearest ? 'selected' : ''}`}
            onClick={handleNearestClick}
          >
            Nearest
          </div>
        </Grid>
        <Grid className="filterPrice" item xs={12} md={4} xl={2} lg={2}>
          <>
            <Box>
              <Slider
                getAriaLabel={() => 'Price range'}
                value={priceRange}
                onChange={(_, newValue) => setPriceRange(newValue as number[])}
                // onMouseDown={handleMouseDown}
                valueLabelDisplay="auto"
                marks={marks}
                min={0}
                max={25000}
                getAriaValueText={(value: any) => `${value}`}
                // disabled={sliderDisabled}
              />
            </Box>
          </>
        </Grid>
        <Grid className="genderFilter" item xs={12} md={4} xl={2} lg={2}>
          <FormControl variant="outlined" fullWidth className="sortByGender">
            <Select
              labelId="review-select-label"
              value={reviews}
              onChange={e => setReviews(e.target.value)}
              displayEmpty
            >
              <MenuItem value="">
                <em>Reviews</em>
              </MenuItem>
              <MenuItem value="1">1 Star</MenuItem>
              <MenuItem value="2">2 Star</MenuItem>
              <MenuItem value="3">3 Star</MenuItem>
              <MenuItem value="4">4 Star</MenuItem>
              <MenuItem value="5">5 Star</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid className="genderFilter" item xs={12} md={4} xl={2} lg={2}>
          <FormControl variant="outlined" fullWidth className="sortByGender">
            <Select
              labelId="demo-simple-select-label2"
              value={radius}
              // onChange={e => setRadius(e.target.value)}
              onChange={handleRadiusChange}
              displayEmpty
            >
              <MenuItem value="">
                <em>Distance</em>
              </MenuItem>
              <MenuItem value="2">2km Radius</MenuItem>
              <MenuItem value="5">5km Radius</MenuItem>
              <MenuItem value="7">7km Radius</MenuItem>
              <MenuItem value="10">10km Radius</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid className="searchFilter" item xs={12} md={4} xl={2} lg={2}>
          <Button className="mapSearch" onClick={handleSearch}>
            Search
          </Button>
        </Grid>
        <Grid className="clearFilter" item xs={12} md={4} xl={2} lg={2}>
          <Button onClick={handleClearFilters} className="mapSearch">
            Clear
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
export default ListingFilters;
