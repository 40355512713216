import React from 'react';
import { Button, Box } from '@mui/material';
import './QuickLinksSection.css';
import { useNavigate } from 'react-router-dom';

const QuickLinksSection = () => {
  const navigate = useNavigate();
  function gotoFevorite() {
    navigate('/favourite');
  }
  return (
    <Box className="quick-links-section">
      <Button onClick={gotoFevorite} variant="outlined" className="quick-link-button" >My Favourites</Button>
      <Button variant="outlined" className="quick-link-button">List Your Business</Button>
    </Box>
  );
};

export default QuickLinksSection;
