import React, { useEffect, useMemo, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  Button,
} from '@mui/material';
import { icons } from '../../../Assets/icons';
import { useNavigate } from 'react-router-dom';
import BeautySpot from '../BeautySpot';
import './ListSearchItems.css';
import { images } from '../../../Assets/images';

interface VendorAddress {
  [key: string]: string | undefined; // Dynamic keys with string values
}

interface SearchItemObject {
  [key: string]: any; // Allows any number of properties with string keys and any type of value
}

// Define the props interface
interface SearchItems {
  dataForPagination: SearchItemObject[]; // An array of dynamic objects
  setPlotData: Function;
  currentPage: number;
  totalPages: number;
  onPageChange: (newPage: number) => void;
}
const ListSearchItems: React.FC<SearchItems> = ({
  dataForPagination,
  setPlotData,
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const navigate = useNavigate();

  // useEffect(() => {
  //   window.scrollTo(0, 0); // Scroll to top on page change
  // }, [currentPage]);

  useEffect(() => {
    setPlotData(dataForPagination);
  }, [dataForPagination]);

  function gotoShop(vendor_detail_id: any) {
    try {
      localStorage.setItem('shopID', vendor_detail_id.toString());
      navigate('/shop');
    } catch (error) {
      console.error('Error navigating to shop:', error);
    }
  }

  // Function to construct the address
  const constructAddress = (address?: VendorAddress): string => {
    if (!address) return ''; // Return empty if no address
    const parts: string[] = [];
    // Iterate over the keys of the address object
    for (const key in address) {
      if (key !== 'lattitude' && key !== 'longitude' && address[key]) {
        // Check if the value is not empty
        parts.push(address[key]!); // Use non-null assertion since we checked for undefined
      }
    }
    return parts.join(', '); // Join the parts with a comma
  };

  return (
    <>
      {dataForPagination.length > 0 && (
        <BeautySpot
          photo={
            dataForPagination[0]?.vendor_media?.media_name || images.glam9512
          }
          name={dataForPagination[0]?.business_name}
          rating={dataForPagination[0]?.rating || 0}
          gender={dataForPagination[0]?.gender_type}
          address={dataForPagination[0]?.vendor_address}
          vendor_detail_id={dataForPagination[0]?.vendor_detail_id || 0}
        />
      )}

      {dataForPagination.slice(1).map((item, idx) => {
        const hasVendorAddress =
          item.vendor_address && Object.keys(item?.vendor_address).length > 0;
        let locationAddress = '';
        if (hasVendorAddress) {
          locationAddress = constructAddress(item?.vendor_address);
        }
        return (
          <Card
            className="appointmentCard"
            onClick={() => gotoShop(item.vendor_detail_id)}
            key={idx + 1}
          >
            <Box className="cardContainer">
              <Box className="imageContainer">
                <img
                  loading="lazy"
                  src={item?.vendor_media?.media_name || images.glam9512}
                  alt={item?.business_name}
                  className="appointment-image"
                />
              </Box>
              <CardContent className="contentContainer">
                <div className="contentFirst">
                  <div className="contentTitle">
                    <Typography variant="h6" className="title">
                      {item?.business_name}
                    </Typography>
                    <Typography variant="body2" className="price">
                      {item?.gender_type === '1'
                        ? 'Male Salon'
                        : item?.gender_type === '2'
                          ? 'Female Salon'
                          : 'Unisex Salon'}
                    </Typography>
                  </div>
                  <div className="contentRating">
                    <Typography variant="body1" className="ratingText">
                      <span>{item.rating !== '' ? item.rating : ''} </span>
                      <img
                        loading="lazy"
                        src={icons.rating}
                        alt="Rating star icon"
                        className="rating-star-icon"
                      />
                    </Typography>
                  </div>
                </div>
                {hasVendorAddress && (
                  <div className="contentAddress">
                    <Typography variant="body2" className="location">
                      <img
                        loading="lazy"
                        src={images.location}
                        className="locationIcon"
                        alt="Location pin icon"
                      />
                      <span>{locationAddress}</span>
                    </Typography>
                    <Typography variant="body2" className="nextIcon">
                      <img src={icons.blackRightArrow} />
                    </Typography>
                  </div>
                )}
              </CardContent>
            </Box>
          </Card>
        );
      })}

      {totalPages > 1 && (
        <Box
          display="flex"
          className="onlyBottom"
          justifyContent="flex-end"
          alignItems="center"
          mt={4}
        >
          <Button
            variant="contained"
            // onClick={() => handlePageChange(currentPage - 1)}
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
            sx={{ marginRight: '8px' }}
          >
            Previous
          </Button>
          <Typography variant="body1" sx={{ margin: '0 16px' }}>
            Page {currentPage} of {totalPages}
          </Typography>
          <Button
            variant="contained"
            // onClick={() => handlePageChange(currentPage + 1)}
            // disabled={currentPage === totalPages || totalPages === 0}
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </Button>
        </Box>
      )}
    </>
  );
};
export default ListSearchItems;
