import React, { useEffect, useState, useCallback } from 'react';
import ServiceNavigation from './ServiceNavigation/ServiceNavigation';
import ServiceList from './ServiceList/ServiceList';
import Summary from './Summary/Summary';
import { Container, Box, Grid, Typography, Button } from '@mui/material';
import { Tabs } from '@mui/base/Tabs';
import { TabsList as BaseTabsList } from '@mui/base/TabsList';
import { TabPanel as BaseTabPanel } from '@mui/base/TabPanel';
import { buttonClasses } from '@mui/base/Button';
import { Tab as BaseTab, tabClasses } from '@mui/base/Tab';
import './ServiceCategories/ServiceCategories.css';
import { ShopAPI } from '../../../src/hooks/shop';
import toast, { Toaster } from 'react-hot-toast';
import { icons } from '../../Assets/icons';
import { useLocation, useNavigate } from 'react-router-dom';
// Interface
import {
  vendorServicesReqInterface,
  vendorServicesResInterface,
  vendorServicesObject,
  Service,
  PackageService,
} from '../../../src/api/types/vendor_services';
import {
  proffestionalRequestInterface,
  professtionalObject,
  proffestionalAPIResponse,
} from '../../../src/api/types/proffestional';
import {
  appointmentSlotRequestInterface,
  appointmentObject,
  appointmentAPIResponse,
} from '../../../src/api/types/appointment_slots';
// Components
import TeamSelection from '../team/TeamSelection/TeamSelection';
import Professional from '../professional/professional';
import Time from '../time/Time';
import ServiceSelection from '../confirm/ServiceSelection/ServiceSelection';
import ReviewConfirm from '../review/ReviewConfirm/ReviewConfirm';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import { VendorDetailData } from '../../../src/api/types/shop';
import {
  appointmentCreateRequestInterface,
  appointmentCreateResponse,
} from '../../../src/api/types/appointment';
import { useBreadcrumbs } from '../../contexts/BreadcrumbContext';
// import { BreadcrumbProvider } from '../../contexts/BreadcrumbContext';

// Styles
const Tab = styled(BaseTab)`
  font-family: 'Poppins';
  color: #fff;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: transparent;
  width: 100%;
  padding: 10px 12px;
  margin: 6px;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: black;
    color: white;
  }

  &:focus {
    color: #fff;
    outline: 3px solid black;
  }

  &.${tabClasses.selected} {
    background-color: #fff;
    color: black;
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
const TabPanel = styled(BaseTabPanel)(
  ({ theme }) => `
  width: 100%;
  font-family: Poppins;
  font-size: 0.875rem;
  padding: 20px 12px;
  background: ${theme.palette.mode === 'dark' ? 'white' : 'white'};
  border: 1px solid ${theme.palette.mode === 'dark' ? 'white' : 'white'};
  border-radius: 12px;
  `,
);
const TabsList = styled(BaseTabsList)(
  ({ theme }) => `
  min-width: 400px;
  background-color: white;
  border-radius: 12px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  `,
);

const BookingAppointment: React.FC = () => {
  const navigate = useNavigate();
  // Hooks
  const {
    getVendorServicesAPI,
    getProfessionalAPI,
    getAppoinmentSlotsAPICall,
    checkAppointmentAvaibilityAPICall,
    createAppointmentAPICall,
  } = ShopAPI();
  // States
  const [vendorID, setVendorID] = useState<number>(0);
  const [vendorData, setVendorData] = useState<VendorDetailData>();
  const [vendorDataDetail, setVendorDataDetail] = useState<any>({});
  const [vendorServicesData, setVendorServicesData] = useState<
    vendorServicesObject[] | null
  >(null);

  const [bookingNotes, setBookingNotes] = useState(''); // New state for booking notes

  // Booking notes Form review component
  const handleBookingNotesChange = (notes: string) => {
    setBookingNotes(notes);
  };

  const [selectedServices, setSelectedServices] = useState<Service[]>([]);
  let [shopCategoryID, setShopCategoryID] = useState('');
  const [shopServiceID, setShopServiceID] = useState('');
  const [serviceIDS, setServiceIDS] = useState<any>([]);
  const [bookPage, setBookPage] = useState<number>(1);
  // Sub States
  const [teamSelectionType, setTeamSelectionType] = useState<number>(1);
  const [vendorServiceMembers, setendorServiceMemebers] = useState<
    professtionalObject[]
  >([]);
  const [selectedTab, setSelectedTab] = useState('');
  const [selectedServiceID, setSelectedServiceID] = useState<any>(null);
  const [appointmentData, setAppointmentData] = useState<appointmentObject[]>(
    [],
  );
  const [visitedFirstTime, setIsVisitedFirstTime] = useState<boolean>(false);
  const [discountAmount, setDiscountAmount] = useState(0);
  // Date & Time
  const [appointmentDate, setAppointmentDate] = useState('');
  const [appointmentTime, setAppointmentTime] = useState('');
  const [totalHours, setTotalHours] = useState(0);
  const [isSlotAvailable, setIsSlotAvailbel] = useState(true);
  const [customerData, setCustomerData] = useState<any>(null);

  const [bookAppointmentLoader, setBookAppointmentLoader] =
    useState<boolean>(false);

  const location = useLocation();

  const [isRescheduling, setIsRescheduling] = useState(false);

  const [reschedulingData, setReschedulingData] = useState({});
  const [reschedulingServicesData, setReschedulingServicesData] = useState([]);

  useEffect(() => {
    if (location.state?.isRescheduling) {
      const data = localStorage.getItem('reschedule_appointment_data') || '{}';
      const parsedData = JSON.parse(data);
      vendorServices(parseInt(parsedData?.vendor_detail_id));

      setBookPage(3); // Jump directly to time selection
      setIsRescheduling(true);
      setReschedulingData(parsedData);
      setReschedulingServicesData(parsedData?.services);

      setIsSlotAvailbel(false);
      getAppoinmentSlotsAPICallFunc(parsedData?.vendor_detail_id, '', '');
    }
  }, [location.state]);

  const {
    breadcrumbs,
    setBreadcrumbs,
    visitedSteps,
    setVisitedSteps,
    appointMentJourney,
    setAppointMentJourney,
  } = useBreadcrumbs();

  useEffect(() => {
    let customerInfo = localStorage.getItem('customer_details');
    if (customerInfo) {
      customerInfo = JSON.parse(customerInfo);
      setCustomerData(customerInfo);
    }
  }, []);

  useEffect(() => {
    // Set the first tab as selected when vendorServicesData is available
    if (vendorServicesData && vendorServicesData.length > 0) {
      setSelectedTab(`tab_index_${vendorServicesData[0].category_id}`);
    }
  }, [vendorServicesData]);

  // Get Services
  const vendorServices = useCallback(
    async (vendor_detail_id: number) => {
      const requestData: vendorServicesReqInterface = {
        vendor_detail_id: vendor_detail_id,
      };
      const result: vendorServicesResInterface | null =
        await getVendorServicesAPI(requestData);
      if (result === null) {
        toast.error('Failed to fetch vendor services data.');
        return; // Exit the function early if the result is null
      }
      if (result?.status_code === 200) {
        setVendorServicesData(result.data);
      } else {
        toast.error(result?.message);
      }
    },
    [getVendorServicesAPI],
  );
  const servicesAddRemove = (serviceObject: Service, checked: boolean) => {
    const normalizedServiceObject = {
      ...serviceObject,
      // charges: serviceObject.charges === '' ? '0' : serviceObject.charges,
      charges: serviceObject.charges === '' ? '0' : serviceObject.charges,
    };

    if (checked) {
      setSelectedServices([...selectedServices, normalizedServiceObject]);
    } else {
      setSelectedServices(
        selectedServices.filter(item => item.id !== serviceObject.id),
      );
    }

    // if (checked) {
    //   setSelectedServices([...selectedServices, serviceObject]);
    // } else {
    //   setSelectedServices(
    //     selectedServices.filter(item => item.id !== serviceObject.id),
    //   );
    // }
  };
  // On Load Functions
  useEffect(() => {
    const shopID = localStorage.getItem('shopID');
    const shopCategoryIDLocal = localStorage.getItem('shop_category_id');

    // book now button in sidebar book detail page
    const valFromBookNowSidebar = JSON.parse(
      localStorage.getItem('bookFromSidebar') || 'false',
    );
    let shopCategoryIDSidebar = localStorage.getItem('shop_category_id') || '';

    const shopServiceIDlocal = JSON.parse(
      localStorage.getItem('shop_service_id') || '{}',
    );

    // const shopID = "35";
    if (shopID) {
      setVendorID(parseInt(shopID));
      vendorServices(parseInt(shopID));
    }

    // if (shopCategoryIDLocal && shopServiceIDlocal) {
    //   setShopCategoryID(shopCategoryIDLocal);
    //   
    //   setShopServiceID(shopServiceIDlocal);
    //   setSelectedServices([shopServiceIDlocal]);
    //   setAppointMentJourney((prevJourney: any) => ({
    //     ...prevJourney,
    //     ['selectedService']: shopServiceIDlocal,
    //   }));
    // }

    if (Object.keys(shopServiceIDlocal).length > 0) {
      if (shopCategoryIDLocal !== null) {
        setShopCategoryID(shopCategoryIDLocal);
      }

      setShopServiceID(shopServiceIDlocal);
      setSelectedServices([shopServiceIDlocal]);
      setAppointMentJourney((prevJourney: any) => ({
        ...prevJourney,
        ['selectedService']: shopServiceIDlocal,
      }));
    } else if (valFromBookNowSidebar) {
      setShopCategoryID(shopCategoryIDSidebar);
    }

    const oldVisitedStep = JSON.parse(
      localStorage.getItem('visitedSteps') || '[]',
    );

    if (oldVisitedStep.length > 0) {
      let lastStep = oldVisitedStep.pop();
      setBookPage(lastStep);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('selected_services', JSON.stringify(selectedServices));
    const idsArray = selectedServices.map(obj => obj.id);
    setServiceIDS(idsArray);
  }, [selectedServices]);

  useEffect(() => {
    if (
      vendorServicesData &&
      vendorServicesData.length > 0 &&
      shopCategoryID &&
      shopServiceID
    ) {
      setSelectedTab(`tab_index_${vendorServicesData[0].category_id}`);
      let selectedCategory = vendorServicesData.filter(row => {
        return row.category_id === shopCategoryID;
      });

      if (selectedCategory && selectedCategory.length > 0) {
        let categoryServices = selectedCategory[0].services;
        if (categoryServices && categoryServices.length > 0) {
          let filterService = categoryServices.filter(row => {
            return row.id === shopServiceID;
          });

          if (filterService && filterService.length > 0) {
            setSelectedServices(filterService);
          }
        }
      }
    }
  }, [vendorServicesData, shopCategoryID, shopServiceID]);

  useEffect(() => {
    const vendorDataLocal = localStorage.getItem('vendor_data');
    if (vendorDataLocal) {
      const vendorDataInfo = JSON.parse(vendorDataLocal);
      const vendorDataDetailObj = vendorDataInfo?.vendor_detail || {};

      const vendorDataDetail = vendorDataDetailObj?.data || {};

      setVendorData(vendorDataInfo);
      setVendorDataDetail(vendorDataDetail);
    }
  }, [setVendorData]);

  const handleTabChange = (event: any, newValue: any) => {
    setSelectedTab(newValue);
  };

  function handleClose() {
    setopenConfirm(false);
    navigate('/appointment');
  }

  const navigationFunc = (navigateLInk: string) => {
    navigate(navigateLInk);
  };
  const [openConfirm, setopenConfirm] = useState(false);

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  // Page
  const nextPage = async () => {
    let nextPageValue = bookPage + 1;
    if (nextPageValue === 3) {
      setIsSlotAvailbel(false);
      getAppoinmentSlotsAPICallFunc(vendorID, '', '');
      
    }
    if (nextPageValue === 2) {
      setTeamSelectionType(1); 
     setIsSlotAvailbel(false);
    }

    if (nextPageValue < 6) {
      if (nextPageValue === 2 && vendorDataDetail?.show_team_member !== '1') {
        setBreadcrumbs(prevBreadcrumbes =>
          prevBreadcrumbes.map(breadcrumb =>
            breadcrumb.id === nextPageValue
              ? { ...breadcrumb, isVisited: true }
              : breadcrumb,
          ),
        );
        setBookPage(nextPageValue + 1);
        getAppoinmentSlotsAPICallFunc(vendorID, '', '');
      } else if (nextPageValue === 4 && vendorDataDetail?.is_first !== '1') {
        setBreadcrumbs(prevBreadcrumbes =>
          prevBreadcrumbes.map(breadcrumb =>
            breadcrumb.id === nextPageValue
              ? { ...breadcrumb, isVisited: true }
              : breadcrumb,
          ),
        );
        setBookPage(nextPageValue + 1);
      } else {
        setBreadcrumbs(prevBreadcrumbes =>
          prevBreadcrumbes.map(breadcrumb =>
            breadcrumb.id === bookPage
              ? { ...breadcrumb, isVisited: true }
              : breadcrumb,
          ),
        );
        setBookPage(nextPageValue);
      }
      // setBookPage(nextPageValue);
    }
    if (nextPageValue === 6) {
      await createAppointmentAPICallFunc();
    }
  };

  

  // Team Section
  const selectTeam = (teamType: number) => {
    // if (teamType === 1) {
    //   setTeamSelectionType(1);
    //   getAppoinmentSlotsAPICallFunc();
    //   setBookPage(3);
    // }
    // if (teamType === 2) {
    //   setTeamSelectionType(2);
    // }

    let selectTeam = {};
    if (teamType === 1) {
      setTeamSelectionType(1);
     getAppoinmentSlotsAPICallFunc(vendorID, '', '');
     setBookPage(3);
     setIsSlotAvailbel(false);

      selectTeam = {
        teamType: 1,
        team: '',
        name: 'Any Professional',
        description: 'For Maximum Availability',
        isSpecial: true,
      };

      setAppointMentJourney((prevJourney: any) => ({
        ...prevJourney,
        ['selectTeam']: selectTeam,
      }));
    }
    if (teamType === 2) {
     setTeamSelectionType(2);

      selectTeam = {
        teamType: 2,
        name: 'Select Professional',
        description: 'Per Service',
        isSpecial: true,
      };

      setAppointMentJourney((prevJourney: any) => ({
        ...prevJourney,
        ['selectTeam']: selectTeam,
      }));
    }
  };

  // Get Professtional
  const getProfesstionalFunc = async (serviceObject: Service) => {
    const requestBody: proffestionalRequestInterface = {
      vendor_detail_id: vendorID,
      category_id: parseInt(serviceObject.category_id),
      service_id: parseInt(serviceObject.id),
    };
    setSelectedServiceID(serviceObject.id);
    const getServiceUsers: proffestionalAPIResponse =
      await getProfessionalAPI(requestBody);
    if (getServiceUsers && getServiceUsers.data) {
      setendorServiceMemebers(getServiceUsers.data);
    } else {
      toast.error('Professional not found.', { duration: 1000 });
    }
  };

  // Select Professtional
  const selectProfesstionalFunc = (
    professtionalObject: professtionalObject,
  ) => {
    // let findIndex =
    let updateData: any = {
      selectedProfessional:
        professtionalObject.first_name + ' ' + professtionalObject.last_name,
      selectedProfessionalID: professtionalObject.id,
    };
    // Recursive function to update the services array
    const updateServices = (services: any[]): any[] => {
      return services.map(service => {
        // If the service matches the selectedServiceID, update it
        if (service.id === selectedServiceID) {
          return { ...service, ...updateData };
        }

        // If the service contains a nested 'services' array, recurse
        if (service.services && Array.isArray(service.services)) {
          return { ...service, services: updateServices(service.services) };
        }

        // If no nested 'services' array, return the service unchanged
        return service;
      });
    };
    setSelectedServices(prevItems =>
      prevItems.map(item => {
        // If the current item has a nested 'services' array, use the recursive update function
        if (item.services && Array.isArray(item.services)) {
          return { ...item, services: updateServices(item.services) };
        }
        // Otherwise, perform a direct update
        return item.id === selectedServiceID
          ? { ...item, ...updateData }
          : item;
      }),
    );

    // setSelectedServices(prevItems =>
    //   prevItems.map(item =>
    //     item.id === selectedServiceID ? { ...item, ...updateData } : item,
    //   ),
    // );

    setendorServiceMemebers([]);
  };

  const getAppoinmentSlotsAPICallFunc = async (
    vendor_detail_id: number,
    month: string,
    year: string,
  ) => {
    const req: appointmentSlotRequestInterface = {
      vendor_detail_id: vendor_detail_id,
      month: month,
      year: year,
    };
    const result: appointmentAPIResponse = await getAppoinmentSlotsAPICall(req);
    if (result === null) {
      toast.error('Failed to fetch vendor services data.');
      return; // Exit the function early if the result is null
    }
    if (result?.status_code === 200) {
      setAppointmentData(result.data ?? []);
    } else {
      toast.error(result?.message);
    }
  };

  const appointmentSelectedFunction = async (date: string, time: string) => {
    setAppointmentDate(date);
    setAppointmentTime(time);
  };

  const checkAppointmentAvaibilityAPICallFunc = async (
    date: string,
    time: string,
  ) => {
    setAppointmentDate(date);
    setAppointmentTime(time);
    let serviceObjectArray: any = [];
    let totalHours = 0;

    const processServices = (services: any[]) => {
      services.forEach(service => {
        // If the service contains a nested 'services' array, process only the nested array
        if (service.services && Array.isArray(service.services)) {
          processServices(service.services);
        } else {
          // Accumulate total hours
          totalHours += parseInt(service.service_hour || 0);

          // Push service details to the array
          serviceObjectArray.push({
            service_id: service.id,
            member_id: service.selectedProfessionalID,
          });
        }
      });
    };
    processServices(selectedServices);
    setTotalHours(totalHours);

    let checkAppointmentReq = {
      vendor_detail_id: vendorID,
      customer_id: customerData.customer_id,
      services: JSON.stringify(serviceObjectArray),
      appointment_date: date,
      slot_time: time,
    };
    const result: any =
      await checkAppointmentAvaibilityAPICall(checkAppointmentReq);

    if (result === null) {
      toast.error('Failed to check appointment slots.');
      return; // Exit the function early if the result is null
    }
    if (result?.status_code === 200) {
      setIsSlotAvailbel(true);
      // setBookPage(4);
    } else {
      setIsSlotAvailbel(false);
      // toast.error(result?.message);
      toast.error('Appointment slot is not available.', {
        duration: 1000,
      });
    }
  };

  const createAppointmentAPICallFunc = async () => {
    try {
      setBookAppointmentLoader(true);

      let serviceObjectArray: any = [];
      const processServices = (services: any[]) => {
        services.forEach(service => {
          // If the service contains a nested 'services' array, process only the nested array
          if (service.services && Array.isArray(service.services)) {
            // processServices(service.services);

            if (service.hasOwnProperty('package_name')) {
              service.services.forEach((packageService: PackageService) => {
                serviceObjectArray.push({
                  service_id: packageService.id,
                  member_id: packageService.member_id,
                  package_id: service.id,
                });
              });
            }
          } else {
            // Push service details to the array
            serviceObjectArray.push({
              service_id: service.id,
              member_id: service.member_id,
              package_id: '',
            });
          }
        });
      };
      processServices(selectedServices);
      // selectedServices.forEach(object => {
      //   let reqObject = {
      //     service_id: object.id,
      //     member_id: object.selectedProfessionalID
      //       ? object.selectedProfessionalID
      //       : 0,
      //   };
      //   serviceObjectArray.push(reqObject);
      // });

      const req: appointmentCreateRequestInterface = {
        vendor_detail_id: vendorID,
        customer_id: customerData.customer_id,
        services: JSON.stringify(serviceObjectArray),
        appointment_date: appointmentDate,
        slot_time: appointmentTime,
        is_first_time: visitedFirstTime ? 1 : 2,
        payment_type: 1,
        booking_notes: bookingNotes,
      };
      const result: appointmentCreateResponse =
        await createAppointmentAPICall(req);
      if (!result) {
        setBookAppointmentLoader(false);
        toast.error('Failed to book an appointment');
        return; // Exit the function early if the result is null
      }
      if (result?.status_code === 200) {
        setopenConfirm(true);
        [
          'shop_category_id',
          'shop_service_id',
          'selected_services',
          'shopID',
          'appointMentJourney',
        ].forEach(item => localStorage.removeItem(item));
      } else {
        toast.error(result?.message || 'Something went wrong while booking.');
      }
    } catch (error: any) {
      toast.error(error.message || 'Something went wrong');
    } finally {
      setBookAppointmentLoader(false);
    }
  };

  useEffect(() => {
    if (!visitedSteps.includes(bookPage)) {
      const updatedVisited = [...visitedSteps, bookPage];
      setVisitedSteps(updatedVisited);
      // localStorage.setItem('visitedSteps', JSON.stringify(updatedVisited));
    }
  }, [bookPage, visitedSteps]);

  useEffect(() => {
    localStorage.setItem(
      'appointMentJourney',
      JSON.stringify(appointMentJourney),
    );
  }, [appointMentJourney]);

  useEffect(() => {
    setBreadcrumbs(prevBreadcrumbes =>
      prevBreadcrumbes.map(breadcrumb =>
        breadcrumb.id === bookPage
          ? { ...breadcrumb, isVisited: true }
          : breadcrumb,
      ),
    );
  }, [setBreadcrumbs]);

  

  return (
    <Box
      className="mainBox"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        bgcolor: 'white',
      }}
    >
      <Container className="booking" component="main">
        <Grid
          className="mainNavigation"
          container
          spacing={5}
          sx={{ flexDirection: { xs: 'column', md: 'row' } }}
        >
          <Grid item xs={12} md={12} className="beforeService">
            <ServiceNavigation
              setTeamSelectionType={setTeamSelectionType}
              navigationFunc={navigationFunc}
              setBookPage={setBookPage}
              bookPage={bookPage}
              setIsSlotAvailbel={setIsSlotAvailbel}
              vendorDataDetail={vendorDataDetail}
            />
          </Grid>
        </Grid>

        <Grid
          className="serviceContainer"
          container
          spacing={5}
          sx={{
            flexDirection: { xs: 'column', md: 'row' },
            marginTop: '0px',
            marginBottom: '100px',
          }}
        >
          <Grid item className="serviceContainerGrid" xs={12} md={12}>
            <Grid container sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
              {bookPage === 1 && (
                <Grid item xs={12} md={9} sx={{ paddingRight: '5%' }}>
                  <Typography
                    variant="h3"
                    component="h1"
                    className="serviceMainTitle"
                    sx={{}}
                  >
                    Select Services
                  </Typography>

                  {vendorServicesData && vendorServicesData?.length > 0 ? (
                    <>
                      <Tabs
                        className="bookingTab"
                        onChange={handleTabChange}
                        defaultValue={`tab_index_${shopCategoryID}`}
                        id={`tab_index_${shopCategoryID}`}
                      >
                        <TabsList>
                          {vendorServicesData &&
                            vendorServicesData.length > 0 &&
                            vendorServicesData.map(
                              (row: vendorServicesObject, index: number) => (
                                <Tab
                                  key={index}
                                  id={row.category_id}
                                  value={`tab_index_${row.category_id}`}
                                  className={
                                    row.category_id != ''
                                      ? 'activeServicePurple'
                                      : 'activeServiceBlack'
                                  }
                                >
                                  <Typography className="service-item-text">
                                    {row.category_name}
                                  </Typography>
                                </Tab>
                              ),
                            )}
                        </TabsList>

                        {vendorServicesData &&
                          vendorServicesData.length > 0 &&
                          vendorServicesData.map(
                            (row: vendorServicesObject, index: number) => {
                              return (
                                <TabPanel
                                  key={index}
                                  value={`tab_index_${row.category_id}`}
                                  className={`tab_index_${row.category_id}`}
                                >
                                  <ServiceList
                                    serviceIDS={serviceIDS}
                                    servicesAddRemove={servicesAddRemove}
                                    data={
                                      row.services.length > 0
                                        ? row.services
                                        : row.packages
                                    }
                                    catName={row.category_name}
                                  />
                                </TabPanel>
                              );
                            },
                          )}
                      </Tabs>
                    </>
                  ) : (
                    ''
                  )}
                </Grid>
              )}
              {bookPage === 2 && vendorDataDetail?.show_team_member === '1' && (
                <TeamSelection
                  selectTeam={selectTeam}
                  teamSelectionType={teamSelectionType}
                  vendorServiceMembers={vendorServiceMembers}
                  getProfesstionalFunc={getProfesstionalFunc}
                  data={selectedServices}
                  selectProfesstionalFunc={selectProfesstionalFunc}
                  bookPage={bookPage}
                />
              )}

              {bookPage === 3 && (
                <Time
                  appointmentData={appointmentData}
                  checkAppointmentAvaibilityAPICallFunc={
                    checkAppointmentAvaibilityAPICallFunc
                  }
                  bookPage={bookPage}
                  getAppoinmentSlotsAPICallFunc={getAppoinmentSlotsAPICallFunc}
                  vendorID={vendorID}
                />
              )}
              {/* {bookPage === 4 && ( */}
              {bookPage === 4 && vendorDataDetail?.is_first === '1' && (
                <ServiceSelection
                  setIsVisitedFirstTime={setIsVisitedFirstTime}
                  visitedFirstTime={visitedFirstTime}
                  vendorData={vendorData}
                  bookPage={bookPage}
                />
              )}

              {bookPage === 5 && (
                <ReviewConfirm
                  setDiscountAmount={setDiscountAmount}
                  setBookingNotes={handleBookingNotesChange} // Pass callback
                />
              )}

              <Grid item xs={12} md={3}>
                <Summary
                  nextPage={nextPage}
                  data={selectedServices}
                  vendorData={vendorData}
                  appointmentDate={appointmentDate}
                  appointmentTime={appointmentTime}
                  totalHours={totalHours}
                  isSlotAvailable={isSlotAvailable}
                  bookPage={bookPage}
                  bookAppointmentLoader={bookAppointmentLoader}
                />
                {/* {isRescheduling ? (
                  <Summary
                    nextPage={nextPage}
                    data={reschedulingServicesData}
                    vendorData={vendorData}
                    appointmentDate={appointmentDate}
                    appointmentTime={appointmentTime}
                    totalHours={totalHours}
                    isSlotAvailable={isSlotAvailable}
                    bookPage={bookPage}
                    bookAppointmentLoader={bookAppointmentLoader}
                  />
                ) : (
                  <Summary
                    nextPage={nextPage}
                    data={selectedServices}
                    vendorData={vendorData}
                    appointmentDate={appointmentDate}
                    appointmentTime={appointmentTime}
                    totalHours={totalHours}
                    isSlotAvailable={isSlotAvailable}
                    bookPage={bookPage}
                    bookAppointmentLoader={bookAppointmentLoader}
                  />
                )} */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openConfirm}
          className="conf"
        >
          <center className="confirmed">
            <div className="image">
              <img src={icons.confirmImage} alt="Right Tick" width="100px" />
            </div>
            <h2>Appointment Confirmed!</h2>
            <p>Your appointment is confirmed with us.</p>
            <Button
              variant="contained"
              onClick={handleClose}
              sx={{
                textTransform: 'capitalize',
                marginTop: '15px',
                padding: '15px 100px',
                borderRadius: '10px',
                backgroundColor: '#4E2A7C',
              }}
            >
              Continue
            </Button>
          </center>
        </BootstrapDialog>
      </Container>
      <Toaster />
    </Box>
  );
};
export default BookingAppointment;

{
  /* {bookPage === 2 && teamSelectionType === 1 && (
                <Professional
                  vendorServiceMembers={vendorServiceMembers}
                  getProfesstionalFunc={getProfesstionalFunc}
                  data={selectedServices}
                  selectProfesstionalFunc={selectProfesstionalFunc}
                />
              )} */
}
